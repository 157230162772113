<template>
  <div>
    <v-dialog
      persistent
      :value="true"
      max-width="750px"
    >
      <v-card>

        <v-card-title class="primary justify-center mb-5">
          システムのご利用にあたって
        </v-card-title>

        <v-card-text class="text-center">
       
          <p class="mb-6" v-if="mode=='update'">「EG Holterシステム」の規約を更新しました。<br />利用を開始するには、更新された利用規約への同意が必要です。<br />以下のリンクをクリックして利用規約を表示し、ご確認ください。</p>
          <p class="mb-6" v-else>「EG Holterシステム」の利用を開始するには、利用規約への同意が必要です。<br />以下のリンクをクリックして利用規約を表示し、ご確認ください。</p>

          <v-sheet
            outlined
            rounded=2
            class="mx-auto pt-6 pb-1 mb-3"
            max-width="550px"
          >
            <div 
              class="term_contents"
              v-for="pdf in pdfs"
              :key="getServiceType(pdf.name)"
            >
              <div>
                <v-icon>mdi-file-pdf-box</v-icon><a :href="`/pdf/${pdf.name}`" target="_blank">{{ pdf.label + '（PDFを表示）' }}</a>
              </div>
              <v-checkbox
                width="300px"
                dense
                hide-details
                v-model="checkBoxValues[getServiceType(pdf.name)]"
                class="term_checkbox"
                @change="changeCheckbox"
              >
                <template v-slot:label>
                  <span style="font-size: 0.875rem">利用規約に同意する</span>
                </template>
              </v-checkbox>
            </div>
          </v-sheet>

        </v-card-text>

        <v-card-actions
          class="justify-center pb-6 pl-10 pr-10"
        >
          <v-btn
            class="pl-10 pr-10 mr-11"
            large
            elevation=2 
            @click="disagree"
          >
            同意しない
          </v-btn>
          <v-btn
            class="pl-10 pr-10"
            large
            elevation=2
            color="secondary"
            :disabled="!isAgreedAll"
            @click="agree"
          >
            同意して利用を開始する
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
  name: 'TermsOfServiceDialog',
  components: {
  },
  mixins: [
  ],
  props: {
  },
  data() {
    return {
      checkBoxValues: {},
      egHolterCheckBox: false,
      myHolterCheckBox: false,
      isAgreedAll: false,
      pdfs: [],
      mode: 'new',
    }
  },
  methods: {
    changeCheckbox() {
      for (const key in this.checkBoxValues) {
        const value = this.checkBoxValues[key];
        if (!value) {
          this.isAgreedAll = false;
          return;
        }
      }
      this.isAgreedAll = true;
    },
    getServiceType(fileName) {
      const values = fileName.split('-');
      return values[1];
    },
    getTermsVersion(fileName) {
      const values = fileName.split('-');
      let version = values[2].split('_');
      for (let i = 0; i < version.length; i++) {
        version[i] = parseInt(version[i]);
      }
      return version.join('.');
    },
    disagree() {
      this.$auth.signOut().then(() => {
        this.$functions.log({
          tags: ['auth', 'logout'],
          message: `ログアウトしました。`,
        });
        this.$router.replace('login');
        this.$store.commit('setUser', null);
      });
    },
    async agree() {
      // なりすましの場合は、なりすまし後ではなく、なりすまし前の情報を利用する
      const user = this.$store.state.superUser != null ? this.$store.state.superUser : this.$store.state.user;

      // スタッフ情報検索
      const userType = user.role.startsWith('customer') ? 'customer_staffs': 'staffs';
      const snapshot = await this.$db.collection(userType)
        .where('deleted', '==', null)
        .where('email', '==', user.email)
        .get();
      if (snapshot.empty) {
        this.$root.$alert('エラー', '未登録のユーザーです。');
        this.disagree();
        return;
      }
      // 同意記録
      let updateData = {
        terms_of_services: {},
      };
      let msg = '';
      for (const pdf of this.pdfs) {
        const type = this.getServiceType(pdf.name);
        const version = this.getTermsVersion(pdf.name);
        updateData.terms_of_services[type] = {
          label: pdf.label,
          version,
          datetime: this.$firebase.firestore.FieldValue.serverTimestamp(),
        }
        msg = msg + `${type}(v${version}):`; 
      }
      await this.$root.$progressive(snapshot.docs[0].ref.set(updateData,{merge:true}));
      // 操作履歴
      this.$functions.log({
        tags: ['auth', 'terms_of_services'],
        message: `${msg}利用規約に同意しました。`,
      });
      // 画面遷移
      this.$router.push({
        name: 'home',
        params: {
          isFirstLogin: true,
        },
      });
    },
  },
  watch: {
  },
  created() {
    if ('showTerms' in this.$store.state && this.$store.state.showTerms && this.$store.state.showTerms.pdfs.length > 0) {
      this.pdfs = this.$store.state.showTerms.pdfs;
      this.mode = this.$store.state.showTerms.mode;
    }
    for (const pdf of this.pdfs) {
      this.checkBoxValues[this.getServiceType(pdf.name)] = false;
    }
  },
  mounted() {
  },
  beforeDestroy() {
    this.pdfs = [];
    this.$store.dispatch('setShowTerms', {});
  }
}
</script>

<style scoped>
  .primary {
    color: white;
  }

  .term_contents {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 16px;
  }

  .term_checkbox {
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 11px 24px 9px 24px;
    margin: 11px 0 1px 0;
  }

  .v-input--checkbox .v-label {
    font-size: 12px !important;
  }

</style>